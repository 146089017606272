<template>

	<main class="main">
		<div class="personal">
			<div class="container personal-inner">
				<div class="row">
					<div class="personal-cnt col-md-4 offset-md-4">
						<a class="personal-logo personal-logo--mb" href="#">
							<img src="../../../../../shared/assets/styles/images/logo.svg" alt="logo">
						</a>
						<validation-observer ref="observer" v-slot="{ handleSubmit }">
							<b-form class="personal-form" @submit.stop.prevent="handleSubmit(requestResetPassword)">

								<div v-if="showMessage" class="alert alert-dark" role="alert">
									If a matching account was found, an email was sent to <a
										:href="`mailto: ${emailWasEntered}`">{{emailWasEntered}}</a>
								</div>
								<ValidationProvider rules="required|email" name="Email" vid="email">
									<b-form-group label="Email"
												  label-for="email"
												  slot-scope="{ errors }">
										<b-form-input
												name="email"
												id="email"
												v-model="email"
												:state="errors[0] ? false : null"
												placeholder="Email">
										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<div class="form-group">
									<input class="btn btn-primary" type="submit" value="Send Password Reset Link">
								</div>
								<p class="personal-links">
									<router-link :to="{name: 'app.login'}">Back to Sign In</router-link>
								</p>
							</b-form>
						</validation-observer>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
    export default {
        name: "ForgotPassword",
        data() {
            return {
                email: null,
                emailWasEntered: null,
                showMessage: false
            }
        },
        methods: {
            requestResetPassword() {
                this.showMessage = false;
                return this.$store.dispatch('auth/forgotPassword', {email: this.email})
                    .then(() => {
                        this.showMessage = true;
                        this.emailWasEntered = this.email;
                        this.email = '';

                        this.$nextTick(() => {
                            this.$refs.observer.setErrors({});
                            this.$nextTick(() => {
                                this.$refs.observer.reset();
                            });
                        });

                    }).catch((err) => {
                    if (!err.response) {
                        this.showNotifications({message: 'Something went wrong!', type: 'danger'})
                    } else if (err.response.status === 422) {
                        this.$refs.observer.setErrors(err.response.data.errors);
                    } else {
                        this.showErrorNotifications(err);
                    }
                    });
            }
        }
    }
</script>

<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/personal";
</style>
